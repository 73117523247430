import React from "react"
import { Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import TitleBlock from "../components/title-block"
import Sidebar from "../components/sidebar"
import Vagaro from "../components/vagaro"

export default ({ data }) => {
  const headerImage = data.headerImage.childImageSharp.fluid

  return (
    <Layout pageInfo={{ pageName: "Schedule an Appointment" }}>
      <SEO title="Schedule an Appointment" />
      <TitleBlock title="Schedule an Appointment" headerImage={headerImage} />
      <div>
        <Row className="px-3">
          <Col lg="8">
            <h2>Schedule Your Appointment Here</h2>
            <p>
              <strong>
                We are open Tues 11:30a-5p, Wed-Thur 10a-7p, Fri 10a-5p and Sat
                1p-4p.
              </strong>
            </p>
            <Vagaro />
          </Col>
          <Col lg="4">
            <Sidebar />
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "default-title-image.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
