import React, { Component } from 'react'

class Vagaro extends Component {
	constructor(props) {
		super(props)
		this.scriptDiv = React.createRef()
	 }

	componentDidMount() {
		const script = document.createElement("script");
		//script.async = true;
		script.type = "text/javascript"
		script.src = "https://www.vagaro.com//resources/WidgetEmbeddedLoader/OZqnC30tDZCcT3qmV35y79oz34mC2PeFJ4mC30m9cyUgR6qCh8wjZAGARAvEpInihcJEvwP4pWUgZawifCs7fY?v=WEuzOiM2rNqEKuhMQAS7gMzjrSa6P5vRtWE0bRRknOj#"
		this.scriptDiv.current.appendChild(script);
	}

	render() {
		return (
			<div className="vagaro" style={{ width: `325px`, padding: `2px`, borderWidth: `thin`,  borderStyle: `double`, margin: `0 auto`, textAlign: `left` }} ref={this.scriptDiv} >
			</div>
		)
	}
}

export default Vagaro